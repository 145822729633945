import axios, { type AxiosInstance, type InternalAxiosRequestConfig } from 'axios';
import { get } from 'svelte/store';
import { auth } from '$stores/auth';
import { browser, dev } from '$app/environment';
import { PUBLIC_COMMON_API_HOST, PUBLIC_COMMON_API_BASE_PATH } from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';
import { toastDanger, serializeParams } from '$lib/utils';

export function setupDefault(instance: AxiosInstance) {
    instance.defaults.paramsSerializer = function (paramObj: Record<string, any>) {
        // axios 에서 query string을 만들 때, 배열이 값으로 들어가면, 'arr[]=1&arr[]=2' 이런식으로 만들어지는데, 이걸 'arr=1&arr=2' 이런식으로 만들어주는 serializer
        // 추가적으로, object가 값으로 들어가면, 'obj=[object Object]' 이런식으로 만들어지는데, 이걸 'obj.a=b&obj.c=d' 이런식으로 만들어주는 serializer
        // object일 경우, 1차 object는 flatten되어 prefix가 붙지 않도록 무시됨.
        // ex) { a: 'b', c: { d: 'e' } } => 'a=b&d=e'
        return serializeParams(paramObj);
    };
}

// 새로만드는 axios instance에 기본 interceptor를 세팅해주는 함수
export function setupInterceptors(instance: AxiosInstance) {
    instance.interceptors.response.use(
        async function (response) {
            // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
            // 응답 데이터가 있는 작업 수행
            return response;
        },
        async function (error) {
            // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
            // 응답 오류가 있는 작업 수행
            const errorMsg = error.response?.data.message || error.message;
            const status = error.response?.status;
            console.log(error);
            if (browser) {
                toastDanger(`${status ? `(${status}) ` : ''}${errorMsg}`);
                if (!dev) {
                    const errorId = crypto.randomUUID();
                    Sentry.captureException(error, { extra: { errorId } });
                }
            }
            return Promise.reject(error);
        },
    );
}

// baseURL이 없는 axios instance를 만드는 함수
export function createAxiosInstance(options = {}) {
    const instance = axios.create(options);
    setupDefault(instance);
    setupInterceptors(instance);
    return instance;
}

export const axiosInstance = createAxiosInstance({
    timeout: 3000,
});

// baseURL을 설정하면서, axiosInstance를 만들고, Authorization header를 세팅해주는 함수
export function createServiceAxiosInstance(serviceRoute: string, options = {}) {
    const baseURL = `${PUBLIC_COMMON_API_HOST}${PUBLIC_COMMON_API_BASE_PATH}${serviceRoute}`;
    const instance = createAxiosInstance({ baseURL, timeout: 10000, ...options });

    instance.interceptors.request.use(
        async function (config: InternalAxiosRequestConfig) {
            const accessToken = get(auth).accessToken;
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }

            config.headers['X-Tenant'] = 1;
            config.headers['X-Workspace'] = 1;

            return config;
        },
        function (error) {
            return Promise.reject(error);
        },
    );

    return instance;
}
