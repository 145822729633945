import { createAxiosInstance } from '$lib/utils';
import { PUBLIC_AI_REVIEW_API_HOST, PUBLIC_AI_REVIEW_API_BASE_PATH } from '$env/static/public';

const baseURL = `${PUBLIC_AI_REVIEW_API_HOST}${PUBLIC_AI_REVIEW_API_BASE_PATH}`;
const axiosInstance = createAxiosInstance({
    baseURL,
    timeout: 10000,
});

// Label 목록 조회
export const getLabels = async (params = {}, options = {}) => {
    const res = await axiosInstance.get('/labels/v2', options);
    return res;
};

// 계약서 타입 목록 조회
export const getContractTypes = async (params = {}, options = {}) => {
    const res = await axiosInstance.get('/contract-types/v2', options);
    return res;
};

// 계약서별 필드 목록 조회
export const getContractFields = async (params = {}, options = {}) => {
    const res = await axiosInstance.get('/contract-fields/v2', options);
    return res;
};

// 계약서 분석
export const postPredict = async (
    { language, contractType, file }: { language: string; contractType: string; file: File },
    options = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    },
) => {
    const formData = new FormData();
    formData.append('language', language);
    formData.append('contractType', contractType);
    formData.append('file', file);

    const res = await axiosInstance.post('/predict/v1', formData, options);
    return res;
};
