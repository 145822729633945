import { ConhubService } from '$lib/api';
import { find, isEmpty } from 'lodash-es';
import { get, derived, writable, type Readable } from 'svelte/store';

interface MetaStore {
    contractMeta: Record<string, any>;
    currencyUnit: any[];
    currencyUnitItems: any[];
    templateItems: any[];
    languageItems: any[];
    codeItems: any[];
    standardFormStatusesItems: any[];
    counterpartyTypeItems: any[];
    getCodeLabel: (code: string) => string;
}

const contractMeta = writable({} as Record<string, Record<string, any>[]>);
const currencyUnit = writable([
    { code: 'KRW', label: '원화' },
    { code: 'USD', label: '달러' },
]);
const codes = writable([
    { code: 'KOREAN', label: '한국어', value: 'KOREAN' },
    { code: 'ENGLISH', label: '영어', value: 'ENGLISH' },
]);
const standardFormStatuses = [
    { label: '배포 중', value: 'ACTIVE' },
    { label: '배포 예정', value: 'INACTIVE' },
];
/**
 * 12월 범위에서 제외 / 상태 코드를 받아서 처리해야 함
 */
// const requestStatuses = ([
//     { label: '배포 중', value: 'ACTIVE' },
//     { label: '배포 예정', value: 'INACTIVE' },
// ]);
const counterpartyTypes = [
    { value: 'CORPORATION', label: '법인사업자' },
    { value: 'SOLE_PROPRIETORSHIP', label: '개인사업자' },
    { value: 'INDIVIDUAL', label: '개인' },
];

export const meta: Readable<MetaStore> = derived([contractMeta, currencyUnit, codes], ([$cm, $cu, $codes], set) => {
    set({
        contractMeta: $cm,
        currencyUnit: $cu,
        currencyUnitItems: $cu.map(item => ({ ...item, label: item.label, value: item.code })),
        templateItems: $cm.templates?.map(template => ({ ...template, label: template.description, value: template.code })),
        languageItems: $cm.languages?.map(lang => ({ ...lang, label: lang.description, value: lang.code })),
        codeItems: $codes,
        counterpartyTypeItems: counterpartyTypes,
        standardFormStatusesItems: standardFormStatuses,
        getCodeLabel: (code: string) => find($codes, ['code', code])?.label || code,
    });
});

// Actions
export const fetchMeta = async () => {
    await Promise.all([fetchContractMeta()]);
};

async function fetchContractMeta() {
    if (!isEmpty(get(contractMeta))) return;
    const res = await ConhubService.getContractMeta();
    contractMeta.set({ ...res.data.data });
}
