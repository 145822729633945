/* eslint-disable */
// https://dev.bhsn.it/api/mtm/swagger-ui/index.html
import { toAxiosOptions, createServiceAxiosInstance } from '$lib/utils';
import type { AxiosPromise } from 'axios';

export const axiosInstance = createServiceAxiosInstance('mtm');

/**
 * @returns LissResponseListUserProfileSimpleVo OK
 * @throws ApiError
 */
export function listLegalReviewers(
    {
        tenantId,
        workspaceId,
    }: {
        tenantId: number;
        workspaceId: number;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseListUserProfileSimpleVo> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/external/users/legal-reviewers/v1',
            query: {
                tenantId: tenantId,
                workspaceId: workspaceId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 워크스페이스 단건 조회
 * @returns LissResponseWorkspaceDto OK
 * @throws ApiError
 */
export function getWorkspace(
    {
        id,
    }: {
        id: number;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseWorkspaceDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/workspaces/{id}/v1',
            path: {
                id: id,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 워크스페이스 수정
 * @returns LissResponseWorkspaceCreatedDto OK
 * @throws ApiError
 */
export function updateWorkspace(
    {
        id,
        requestBody,
    }: {
        id: number;
        requestBody: WorkspaceUpdateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseWorkspaceCreatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/workspaces/{id}/v1',
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 조회
 * @returns LissResponseUserProfileDto OK
 * @throws ApiError
 */
export function getUser(
    {
        userId,
    }: {
        userId: number;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseUserProfileDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/users/{userId}/v1',
            path: {
                userId: userId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 수정
 * @returns LissResponseUserUpdatedDto OK
 * @throws ApiError
 */
export function updatedUser(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: UserUpdateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseUserUpdatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/users/{userId}/v1',
            path: {
                userId: userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 테넌트 조회
 * @returns LissResponseTenantDto OK
 * @throws ApiError
 */
export function getTenant(
    {
        id,
    }: {
        id: number;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseTenantDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/tenants/{id}/v1',
            path: {
                id: id,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 테넌트 수정
 * @returns LissResponseTenantDto OK
 * @throws ApiError
 */
export function updateTenant(
    {
        id,
        requestBody,
    }: {
        id: number;
        requestBody: TenantForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseTenantDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/tenants/{id}/v1',
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 역할 조회
 * @returns LissResponseRolePermissionDto OK
 * @throws ApiError
 */
export function getRole(
    {
        roleId,
    }: {
        roleId: number;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseRolePermissionDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/roles/{roleId}/v1',
            path: {
                roleId: roleId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 역할 수정
 * @returns LissResponseRoleCreatedDto OK
 * @throws ApiError
 */
export function updateRole(
    {
        roleId,
        requestBody,
    }: {
        roleId: number;
        requestBody: RoleUpdateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseRoleCreatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/roles/{roleId}/v1',
            path: {
                roleId: roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 역할 삭제
 * @returns LissResponse OK
 * @throws ApiError
 */
export function deleteRole(
    {
        roleId,
    }: {
        roleId: number;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/roles/{roleId}/v1',
            path: {
                roleId: roleId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 퍼미션 조회
 * @returns LissResponsePermissionDto OK
 * @throws ApiError
 */
export function getPermission(
    {
        id,
    }: {
        id: number;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponsePermissionDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/permissions/{id}/v1',
            path: {
                id: id,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 퍼미션 수정
 * @returns LissResponsePermissionCreatedDto OK
 * @throws ApiError
 */
export function updatePermission(
    {
        id,
        requestBody,
    }: {
        id: number;
        requestBody: PermissionCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponsePermissionCreatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/permissions/{id}/v1',
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 퍼미션 삭제
 * @returns LissResponse OK
 * @throws ApiError
 */
export function deletePermission1(
    {
        id,
    }: {
        id: number;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/permissions/{id}/v1',
            path: {
                id: id,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 직위 수정
 * @returns LissResponseJobPositionDto OK
 * @throws ApiError
 */
export function updateJobPosition(
    {
        jobPositionId,
        requestBody,
    }: {
        jobPositionId: number;
        requestBody: JobPositionUpdateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseJobPositionDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/job-positions/{jobPositionId}/v1',
            path: {
                jobPositionId: jobPositionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 직위 삭제
 * @returns LissResponse OK
 * @throws ApiError
 */
export function deleteJobPosition(
    {
        jobPositionId,
    }: {
        jobPositionId: number;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/job-positions/{jobPositionId}/v1',
            path: {
                jobPositionId: jobPositionId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 그룹 조회
 * @returns LissResponseGroupDto OK
 * @throws ApiError
 */
export function getGroup(
    {
        groupId,
    }: {
        groupId: number;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseGroupDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/groups/{groupId}/v1',
            path: {
                groupId: groupId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 그룹 수정
 * @returns LissResponseGroupDto OK
 * @throws ApiError
 */
export function updateGroup(
    {
        groupId,
        requestBody,
    }: {
        groupId: number;
        requestBody: GroupCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseGroupDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/groups/{groupId}/v1',
            path: {
                groupId: groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 그룹 삭제
 * @returns LissResponse OK
 * @throws ApiError
 */
export function deleteGroup(
    {
        groupId,
    }: {
        groupId: number;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/groups/{groupId}/v1',
            path: {
                groupId: groupId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 워크스페이스 조회
 * @returns LissResponsePageWorkspaceDto OK
 * @throws ApiError
 */
export function pageWorkspaces(
    {
        name,
        businessNumber,
        page,
        size = 30,
        sort,
    }: {
        name?: string;
        businessNumber?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponsePageWorkspaceDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/workspaces/v1',
            query: {
                name: name,
                businessNumber: businessNumber,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 워크스페이스 생성
 * @returns LissResponseWorkspaceCreatedDto OK
 * @throws ApiError
 */
export function createWorkspace(
    {
        requestBody,
    }: {
        requestBody: WorkspaceCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseWorkspaceCreatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/workspaces/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 역할 목록 조회
 * @returns LissResponseListRoleDto OK
 * @throws ApiError
 */
export function getUserRoles(
    {
        userId,
    }: {
        userId: number;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseListRoleDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/users/{userId}/roles/v1',
            path: {
                userId: userId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 역할 벌크 추가
 * @returns LissResponse OK
 * @throws ApiError
 */
export function addUserRoles(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: UserRoleForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/users/{userId}/roles/v1',
            path: {
                userId: userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 역할 벌크 삭제
 * @returns LissResponse OK
 * @throws ApiError
 */
export function deleteUserRoles(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: UserRoleForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/users/{userId}/roles/v1',
            path: {
                userId: userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 초대 목록 조회
 * @returns LissResponsePageUserInvitationDto OK
 * @throws ApiError
 */
export function pageInvitations(
    {
        groupId,
        loginId,
        expired,
        jobPositionIds,
        statuses,
        page,
        size = 30,
        sort,
    }: {
        groupId?: number;
        loginId?: string;
        expired?: boolean;
        jobPositionIds?: Array<number>;
        statuses?: Array<'PENDING' | 'SENT' | 'ACCEPTED' | 'CANCELLED' | 'EXPIRED' | 'FAILED'>;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponsePageUserInvitationDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/user-invitations/v1',
            query: {
                groupId: groupId,
                loginId: loginId,
                expired: expired,
                jobPositionIds: jobPositionIds,
                statuses: statuses,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 초대
 * @returns LissResponse OK
 * @throws ApiError
 */
export function inviteUser(
    {
        requestBody,
    }: {
        requestBody: UserInvitationForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/user-invitations/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 테넌트 목록 조회
 * @returns LissResponsePageTenantDto OK
 * @throws ApiError
 */
export function pageTenants(
    {
        name,
        businessNumber,
        page,
        size = 30,
        sort,
    }: {
        name?: string;
        businessNumber?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponsePageTenantDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/tenants/v1',
            query: {
                name: name,
                businessNumber: businessNumber,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 테넌트 생성
 * @returns LissResponseTenantDto OK
 * @throws ApiError
 */
export function createTenant(
    {
        requestBody,
    }: {
        requestBody: TenantForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseTenantDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/tenants/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 회원가입 - 계정 생성
 * @returns LissResponseUserCreatedDto OK
 * @throws ApiError
 */
export function createUser(
    {
        requestBody,
    }: {
        requestBody: UserCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseUserCreatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/sign-up/users/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 회원가입 - 워크스페이스 추가
 * @returns LissResponseUserWorkspaceCreatedDto OK
 * @throws ApiError
 */
export function createUserWorkspace(
    {
        requestBody,
    }: {
        requestBody: UserWorkspaceCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseUserWorkspaceCreatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/sign-up/user-workspaces/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 역할 - 퍼미션 추가
 * @returns LissResponse OK
 * @throws ApiError
 */
export function addPermissions(
    {
        roleId,
        requestBody,
    }: {
        roleId: number;
        requestBody: RolePermissionForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/roles/{roleId}/permissions/v1',
            path: {
                roleId: roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 역할 - 퍼미션 삭제
 * @returns LissResponse OK
 * @throws ApiError
 */
export function deletePermission(
    {
        roleId,
        requestBody,
    }: {
        roleId: number;
        requestBody: RolePermissionForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/roles/{roleId}/permissions/v1',
            path: {
                roleId: roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 역할 목록 조회
 * @returns LissResponseListRoleDto OK
 * @throws ApiError
 */
export function listRoles(
    {
        name,
    }: {
        name?: string;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseListRoleDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/roles/v1',
            query: {
                name: name,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 역할 생성
 * @returns LissResponseRoleCreatedDto OK
 * @throws ApiError
 */
export function createRole(
    {
        requestBody,
    }: {
        requestBody: RoleCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseRoleCreatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/roles/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 요청 목록 조회
 * @returns LissResponsePageRoleRequestDto OK
 * @throws ApiError
 */
export function pageUserRequests(
    {
        statuses,
        requesterIds,
        roleIds,
        approveUserId,
        page,
        size = 30,
        sort,
    }: {
        statuses?: Array<'REQUESTED' | 'APPROVED' | 'REJECTED'>;
        requesterIds?: Array<number>;
        roleIds?: Array<number>;
        approveUserId?: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponsePageRoleRequestDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/role-requests/users/v1',
            query: {
                statuses: statuses,
                requesterIds: requesterIds,
                roleIds: roleIds,
                approveUserId: approveUserId,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 요청 추가
 * @returns LissResponseListUserRoleRequestCreatedDto OK
 * @throws ApiError
 */
export function createUserRequest(
    {
        requestBody,
    }: {
        requestBody: UserRoleRequestCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseListUserRoleRequestCreatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/role-requests/users/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 요청 거부
 * @returns LissResponse OK
 * @throws ApiError
 */
export function rejectUserRequest(
    {
        requestBody,
    }: {
        requestBody: RoleRequestStateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/role-requests/users/rejected/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 요청 승인
 * @returns LissResponseListApprovedRoleRequestDto OK
 * @throws ApiError
 */
export function approveUserRequest(
    {
        requestBody,
    }: {
        requestBody: RoleRequestStateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseListApprovedRoleRequestDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/role-requests/users/approved/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 요청 목록 조회
 * @returns LissResponsePageRoleRequestDto OK
 * @throws ApiError
 */
export function pageGroupRequests(
    {
        statuses,
        requesterIds,
        roleIds,
        approveUserId,
        page,
        size = 30,
        sort,
    }: {
        statuses?: Array<'REQUESTED' | 'APPROVED' | 'REJECTED'>;
        requesterIds?: Array<number>;
        roleIds?: Array<number>;
        approveUserId?: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponsePageRoleRequestDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/role-requests/groups/v1',
            query: {
                statuses: statuses,
                requesterIds: requesterIds,
                roleIds: roleIds,
                approveUserId: approveUserId,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 요청 추가
 * @returns LissResponseListGroupRoleRequestCreatedDto OK
 * @throws ApiError
 */
export function createGroupRequest(
    {
        requestBody,
    }: {
        requestBody: GroupRoleRequestCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseListGroupRoleRequestCreatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/role-requests/groups/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 요청 거부
 * @returns LissResponse OK
 * @throws ApiError
 */
export function rejectGroupRequest(
    {
        requestBody,
    }: {
        requestBody: RoleRequestStateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/role-requests/groups/rejected/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 요청 승인
 * @returns LissResponseListApprovedRoleRequestDto OK
 * @throws ApiError
 */
export function approveGroupRequest(
    {
        requestBody,
    }: {
        requestBody: RoleRequestStateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseListApprovedRoleRequestDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/role-requests/groups/approved/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 퍼미션 목록 조회
 * @returns LissResponsePagePermissionDto OK
 * @throws ApiError
 */
export function pagePermissions(
    {
        name,
        type,
        page,
        size = 20,
        sort,
    }: {
        name?: string;
        type?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponsePagePermissionDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/permissions/v1',
            query: {
                name: name,
                type: type,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 퍼미션 생성
 * @returns LissResponsePermissionCreatedDto OK
 * @throws ApiError
 */
export function createPermission(
    {
        requestBody,
    }: {
        requestBody: PermissionCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponsePermissionCreatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/permissions/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 직위 벌크 추가
 * @returns LissResponse OK
 * @throws ApiError
 */
export function createJobPosition(
    {
        requestBody,
    }: {
        requestBody: JobPositionAddForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/job-positions/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 그룹 사용자 벌크 수정
 * @returns LissResponse OK
 * @throws ApiError
 */
export function updateGroupUsers(
    {
        groupId,
        requestBody,
    }: {
        groupId: number;
        requestBody: GroupUserForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/groups/{groupId}/users/v1',
            path: {
                groupId: groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 그룹 역할 목록 조회
 * @returns LissResponseListRoleDto OK
 * @throws ApiError
 */
export function getGroupRoles(
    {
        groupId,
    }: {
        groupId: number;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseListRoleDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/groups/{groupId}/roles/v1',
            path: {
                groupId: groupId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 그룹 역할 벌크 수정
 * @returns LissResponse OK
 * @throws ApiError
 */
export function addGroupRoles(
    {
        groupId,
        requestBody,
    }: {
        groupId: number;
        requestBody: GroupRoleForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/groups/{groupId}/roles/v1',
            path: {
                groupId: groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 그룹 역할 벌크 삭제
 * @returns LissResponse OK
 * @throws ApiError
 */
export function deleteGroupRoles(
    {
        groupId,
        requestBody,
    }: {
        groupId: number;
        requestBody: GroupRoleForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/groups/{groupId}/roles/v1',
            path: {
                groupId: groupId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 그룹 목록 조회
 * @returns LissResponsePageGroupDto OK
 * @throws ApiError
 */
export function pageGroups(
    {
        parentId,
        name,
        page,
        size = 30,
        sort,
    }: {
        parentId?: number;
        name?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponsePageGroupDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/groups/v1',
            query: {
                parentId: parentId,
                name: name,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 그룹 생성
 * @returns LissResponseGroupDto OK
 * @throws ApiError
 */
export function createGroup(
    {
        requestBody,
    }: {
        requestBody: GroupCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseGroupDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/groups/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 조회(overview)
 * @returns LissResponseUserOverviewDto OK
 * @throws ApiError
 */
export function getUserOverview(
    {
        userId,
    }: {
        userId: number;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponseUserOverviewDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/users/{userId}/overview/v1',
            path: {
                userId: userId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 목록 페이징 조회
 * @returns LissResponsePageUserDto OK
 * @throws ApiError
 */
export function pageUsers(
    {
        groupId,
        loginId,
        name,
        page,
        size = 30,
        sort,
    }: {
        groupId?: number;
        loginId?: string;
        name?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponsePageUserDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/users/v1',
            query: {
                groupId: groupId,
                loginId: loginId,
                name: name,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 목록 조회
 * @returns LissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listUsers(requestConfig: any = {}): AxiosPromise<LissResponseListUserOverviewDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/users/all/v1',
        }),
        ...requestConfig,
    });
}
/**
 * 역할 승인 가능 권한자 목록 조회
 * @returns LissResponseListUserIdentityDto OK
 * @throws ApiError
 */
export function getApproveUsers(requestConfig: any = {}): AxiosPromise<LissResponseListUserIdentityDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/role-requests/approvers/v1',
        }),
        ...requestConfig,
    });
}
/**
 * 내 정보 조회
 * @returns LissResponseMyInfoDto OK
 * @throws ApiError
 */
export function getMyInfo(requestConfig: any = {}): AxiosPromise<LissResponseMyInfoDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/my-info/v1',
        }),
        ...requestConfig,
    });
}
/**
 * 내 그룹 조회
 * @returns LissResponseListGroupDto OK
 * @throws ApiError
 */
export function getMyGroups(requestConfig: any = {}): AxiosPromise<LissResponseListGroupDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/my-info/groups/v1',
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 삭제
 * @returns LissResponse OK
 * @throws ApiError
 */
export function deleteUser(
    {
        id,
    }: {
        id: number;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/users/{id}/v1',
            path: {
                id: id,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 초대 취소
 * @returns LissResponse OK
 * @throws ApiError
 */
export function cancelInvitation(
    {
        invitationId,
    }: {
        invitationId: number;
    },
    requestConfig: any = {},
): AxiosPromise<LissResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/user-invitations/{invitationId}/v1',
            path: {
                invitationId: invitationId,
            },
        }),
        ...requestConfig,
    });
}
