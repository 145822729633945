import { writable } from 'svelte/store';
import { locale, waitLocale } from 'svelte-i18n';
import { defaultLocale } from '$lib/i18n';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

// import dayjs locale
import 'dayjs/locale/en';
import 'dayjs/locale/ko';

// https://day.js.org/docs/en/display/format#list-of-localized-formats
dayjs.extend(localizedFormat);

const initialValue = {
    locale: defaultLocale,
    paramLocale: '',
};

export const masterState = writable(initialValue);

export const setLocale = async (lang?: string) => {
    masterState.update(state => ({
        ...state,
        locale: lang || defaultLocale,
        paramLocale: lang || '',
    }));
    locale.set(lang || defaultLocale);
    dayjs.locale(lang || defaultLocale);
    await waitLocale();
};
