import { browser, building, dev, version } from '$app/environment';
import { HeimdallService, MtmService } from '$lib/api';
import { persisted } from 'svelte-local-storage-store';
import { get, derived, type Writable } from 'svelte/store';
import { goto } from '$app/navigation';
import { axiosInstance, transformUrl } from '$lib/utils';
import { fetchCodes } from '$stores/commonCode';
import { decodeJwt } from 'jose';

export type Auth = TokenPair & {
    userInfo: UserInfo | null;
};

export type UserInfo = {
    [x: string]: any;
};

// State
const initialValue: Auth = {
    accessToken: '',
    refreshToken: '',
    userInfo: null,
};

export const auth: Writable<Auth> = persisted('auth', initialValue);

// State Views
export const session = derived(auth, $auth => {
    return !!$auth.accessToken && !!$auth.refreshToken;
});

// Actions
// 로그인
export const actionSignIn = async ({ loginId, password }: LoginForm) => {
    try {
        const {
            data: { message, data: tokenPair },
        } = await HeimdallService.loginForDev({
            requestBody: { loginId, password },
        });

        if (message) {
            alert(message);
            return false;
        }

        auth.set({
            ...tokenPair,
            userInfo: null,
        });

        // console.log(isAccessTokenExpired(get(auth)));

        await actionRefreshUserInfo();

        // await fetchCodes();
    } catch (err) {
        console.error(err);
        return false;
    }
    return true;
};

// 사용자 정보 갱신
export const actionRefreshUserInfo = async () => {
    if (get(session)) {
        try {
            const res = await MtmService.getMyInfo();
            auth.update($auth => ({ ...$auth, userInfo: res.data.data }));
            // await fetchCodes();
            return true;
        } catch (err) {
            console.error(err);
        }
    }
    return false;
};

// 로그아웃
export const actionSignOut = async () => {
    try {
        await HeimdallService.logout();
    } catch (err) {
        console.error(err);
    }
    auth.update($auth => initialValue);
    await goto(transformUrl('/sign-in'), { replaceState: true });
};

// BE단에서의 인증만료로 인한 사용자 정보 초기화
export const actionClearAuth = async () => {
    auth.update($auth => initialValue);
    // await goto(transformUrl('/'), { replaceState: true }); // 인증만료 후 페이지를 새로고침한 시점에서 이 코드가 불리면 page.param을 읽지 못해서 에러가 생겨서 (need-auth)/+layout.svelte 에서 replace
};

export const isAccessTokenExpired = () => {
    if (!get(session)) return true;

    const { exp } = decodeJwt(get(auth).accessToken) as { exp: number };
    const now = Math.floor(Date.now() / 1000);
    return exp < now;
};

export const actionRefreshToken = async () => {
    try {
        const {
            data: { data: tokenPair },
        } = await HeimdallService.refresh({ requestBody: { refreshToken: get(auth).refreshToken } });
        auth.update($auth => ({ ...$auth, ...tokenPair }));
    } catch (err) {
        actionClearAuth();
        await goto(transformUrl('/sign-in'), { replaceState: true });
    }
};
