import { getCodes } from '$lib/api/common';
import { filter, flatMap, find } from 'lodash-es';
import { get, derived, writable } from 'svelte/store';

// State
const initialValue: {
    [x: string]: any[];
} = {};
export const commonCodes = writable(initialValue);

// State Views
export const commonCodeList = derived(commonCodes, $codes => {
    const flatList = flatMap($codes, (n, idx) => {
        return [...n].map(val => ({ codelevel1_id: idx, ...val }));
    });
    return flatList;
});
export const commonCodeStatus = derived(commonCodes, $commonCodes => $commonCodes?.['ST']);
export const commonCodeContractType = derived(commonCodes, $commonCodes => $commonCodes?.['CA']);
export const commonAvailableLanguageTypes = derived(commonCodes, $commonCodes => filter($commonCodes?.LG, o => ['LGKO', 'LGEN', 'LGOT'].indexOf(o.codelevel2_id) > -1));
export const commonAvailableCompanyTypes = derived(commonCodes, $commonCodes => filter($commonCodes?.CO));
/**
 * eaxmple
 * 	[
 * 		{"codelevel2_id":"PUWO","codelevel2_nm":"원화 WON","type":"","description":null},
 * 		{"codelevel2_id":"PUUS","codelevel2_nm":"달러 USD","type":"","description":null},
 * 		{"codelevel2_id":"PUJP","codelevel2_nm":"일본 엔 JPY","type":"","description":null},
 * 		{"codelevel2_id":"PUEU","codelevel2_nm":"유럽 유로 EUR","type":"","description":null},
 * 		{"codelevel2_id":"PUCN","codelevel2_nm":"중국 위안 CNY","type":"","description":null},
 * 		{"codelevel2_id":"PUTH","codelevel2_nm":"태국 바트 THB","type":"","description":null},
 * 		{"codelevel2_id":"PUVN","codelevel2_nm":"베트남 동 VND","type":"","description":null},
 * 		{"codelevel2_id":"PUOT","codelevel2_nm":"기타통화","type":"","description":null},
 * 		{"codelevel2_id":"PUNA","codelevel2_nm":"해당없음","type":"","description":null}
 * 	]
 */
export const commonAvailablePriceUnitTypes = derived(commonCodes, $commonCodes => filter($commonCodes?.PU));
export const commonAvailableContractTypes = derived(commonCodes, $commonCodes => filter($commonCodes?.CA));
export const getCommonCodeName = derived(commonCodeList, $commonCodeList => (statusCode: string) => find($commonCodeList, { codelevel2_id: statusCode })?.codelevel2_nm || statusCode);

// Actions
export const fetchCodes = async () => {
    try {
        const res = await getCodes();
        commonCodes.set(res.data);
        return true;
    } catch (err) {
        console.error(err);
    }
    return false;
};
