/* eslint-disable */
// https://dev.bhsn.it/api/btls/swagger-ui/index.html
import { toAxiosOptions, createServiceAxiosInstance } from '$lib/utils';
import type { AxiosPromise } from 'axios';

export const axiosInstance = createServiceAxiosInstance('btls');

/**
 * 표준계약서 조회
 * @returns BtlsResponseStandardFormDetailDto OK
 * @throws ApiError
 */
export function getStandardForm(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    },
    requestConfig: any = {},
): AxiosPromise<BtlsResponseStandardFormDetailDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/standard-forms/{standardFormId}/v1',
            path: {
                standardFormId: standardFormId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 수정
 * @returns BtlsResponseStandardFormCreatedDto OK
 * @throws ApiError
 */
export function updateStandardForm(
    {
        standardFormId,
        requestBody,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        requestBody: StandardFormUpdateForm;
    },
    requestConfig: any = {},
): AxiosPromise<BtlsResponseStandardFormCreatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/standard-forms/{standardFormId}/v1',
            path: {
                standardFormId: standardFormId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 삭제
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function deleteStandardForm(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    },
    requestConfig: any = {},
): AxiosPromise<BtlsResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/standard-forms/{standardFormId}/v1',
            path: {
                standardFormId: standardFormId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 템플릿 버전 변경
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function rollbackVersion(
    {
        standardFormId,
        requestBody,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        requestBody: StandardFormTemplateRollbackForm;
    },
    requestConfig: any = {},
): AxiosPromise<BtlsResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/standard-forms/{standardFormId}/templates/rollback/v1',
            path: {
                standardFormId: standardFormId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 활성화 상태 변경
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function changeActiveStatus(
    {
        standardFormId,
        requestBody,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        requestBody: StandardFormActiveStatusForm;
    },
    requestConfig: any = {},
): AxiosPromise<BtlsResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/standard-forms/{standardFormId}/active-status/v1',
            path: {
                standardFormId: standardFormId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 템플릿 조회
 * @returns BtlsResponseListStandardFormTemplateDto OK
 * @throws ApiError
 */
export function listStandardFormTemplates(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    },
    requestConfig: any = {},
): AxiosPromise<BtlsResponseListStandardFormTemplateDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/standard-forms/{standardFormId}/templates/v1',
            path: {
                standardFormId: standardFormId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 양식 업로드
 * @returns BtlsResponseStandardFormTemplateCreatedDto OK
 * @throws ApiError
 */
export function uploadStandardFormTemplate(
    {
        standardFormId,
        formData,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        formData?: {
            form: StandardFormTemplateCreateForm;
            file: Blob;
        };
    },
    requestConfig: any = {},
): AxiosPromise<BtlsResponseStandardFormTemplateCreatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/standard-forms/{standardFormId}/templates/v1',
            path: {
                standardFormId: standardFormId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 북마크 등록
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function registerBookmark(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    },
    requestConfig: any = {},
): AxiosPromise<BtlsResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/standard-forms/{standardFormId}/bookmarks/v1',
            path: {
                standardFormId: standardFormId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 북마크 삭제
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function deleteBookmark(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    },
    requestConfig: any = {},
): AxiosPromise<BtlsResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/standard-forms/{standardFormId}/bookmarks/v1',
            path: {
                standardFormId: standardFormId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 첨부파일 등록
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function createStandardFormAttachment(
    {
        standardFormId,
        requestBody,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        requestBody: StandardFormAttachmentCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<BtlsResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/standard-forms/{standardFormId}/attachments/v1',
            path: {
                standardFormId: standardFormId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 목록 조회
 * @returns BtlsResponsePageStandardFormDto OK
 * @throws ApiError
 */
export function pageByCondition(
    {
        searchKeyword,
        contractTypes,
        activeStatuses,
        languages,
        userIds,
        searchKeywordType,
        sortDirection,
        page,
        size = 30,
        sort,
    }: {
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 계약서 상태
         */
        activeStatuses?: 'ACTIVE' | 'INACTIVE';
        /**
         * 언어
         */
        languages?: 'KOREAN' | 'ENGLISH' | 'ETC';
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 검색 키워드 타입
         */
        searchKeywordType?: 'ALL' | 'NAME' | 'DESCRIPTION' | 'GUIDELINES' | 'USE_CASES' | 'OUR_POSITION';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<BtlsResponsePageStandardFormDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/standard-forms/v1',
            query: {
                searchKeyword: searchKeyword,
                contractTypes: contractTypes,
                activeStatuses: activeStatuses,
                languages: languages,
                userIds: userIds,
                searchKeywordType: searchKeywordType,
                sortDirection: sortDirection,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 생성
 * @returns BtlsResponseStandardFormCreatedDto OK
 * @throws ApiError
 */
export function createStandardForm(
    {
        formData,
    }: {
        formData?: {
            form: StandardFormCreateForm;
            standardFormFile: Blob;
        };
    },
    requestConfig: any = {},
): AxiosPromise<BtlsResponseStandardFormCreatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/standard-forms/v1',
            formData: formData,
            mediaType: 'multipart/form-data',
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 버전 별 조회(document)
 * @returns BtlsResponseStandardFormTemplateDetailDto OK
 * @throws ApiError
 */
export function getTemplateByVersion(
    {
        standardFormId,
        version,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        /**
         * 템플릿 버전
         */
        version: number;
    },
    requestConfig: any = {},
): AxiosPromise<BtlsResponseStandardFormTemplateDetailDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/standard-forms/{standardFormId}/templates/version/{version}/v1',
            path: {
                standardFormId: standardFormId,
                version: version,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 조회(document)
 * @returns BtlsResponseStandardFormTemplateDetailDto OK
 * @throws ApiError
 */
export function getTemplate(
    {
        standardFormId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
    },
    requestConfig: any = {},
): AxiosPromise<BtlsResponseStandardFormTemplateDetailDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/standard-forms/{standardFormId}/templates/latest/v1',
            path: {
                standardFormId: standardFormId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 템플릿 클린버전 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadCleaned(
    {
        standardFormId,
        version,
        format,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        /**
         * 템플릿 버전
         */
        version: number;
        /**
         * 다운로드 포맷
         */
        format: 'DOCX';
    },
    requestConfig: any = {},
): AxiosPromise<any> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/standard-forms/{standardFormId}/templates/download/CLEANED/v1',
            path: {
                standardFormId: standardFormId,
            },
            query: {
                version: version,
                format: format,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 템플릿 원본 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadAll(
    {
        standardFormId,
        version,
        format,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        /**
         * 템플릿 버전
         */
        version: number;
        /**
         * 다운로드 포맷
         */
        format: 'DOCX';
    },
    requestConfig: any = {},
): AxiosPromise<any> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/standard-forms/{standardFormId}/templates/download/ALL/v1',
            path: {
                standardFormId: standardFormId,
            },
            query: {
                version: version,
                format: format,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 첨부파일 다운로드
 * @returns binary OK
 * @throws ApiError
 */
export function downloadStandardFormAttachment(
    {
        standardFormId,
        standardFormAttachmentId,
    }: {
        /**
         * 표준계약서 ID
         */
        standardFormId: number;
        /**
         * 표준계약서 첨부파일 ID
         */
        standardFormAttachmentId: number;
    },
    requestConfig: any = {},
): AxiosPromise<Blob> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/standard-forms/{standardFormId}/attachments/{standardFormAttachmentId}/download/v1',
            path: {
                standardFormId: standardFormId,
                standardFormAttachmentId: standardFormAttachmentId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 북마크 조회
 * @returns BtlsResponsePageStandardFormDto OK
 * @throws ApiError
 */
export function pageByCondition1(
    {
        page,
        size = 30,
        sort,
    }: {
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<BtlsResponsePageStandardFormDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/standard-forms/bookmarks/v1',
            query: {
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 표준계약서 첨부파일 삭제
 * @returns BtlsResponse OK
 * @throws ApiError
 */
export function deleteStandardFormAttachment(
    {
        standardFormId,
        standardFormAttachmentId,
    }: {
        standardFormId: number;
        standardFormAttachmentId: number;
    },
    requestConfig: any = {},
): AxiosPromise<BtlsResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/standard-forms/{standardFormId}/attachments/{standardFormAttachmentId}/v1',
            path: {
                standardFormId: standardFormId,
                standardFormAttachmentId: standardFormAttachmentId,
            },
        }),
        ...requestConfig,
    });
}
