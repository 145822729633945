/* eslint-disable */
// https://dev.bhsn.it/api/conhub/swagger-ui/index.html
import { toAxiosOptions, createServiceAxiosInstance } from '$lib/utils';
import type { AxiosPromise } from 'axios';

export const axiosInstance = createServiceAxiosInstance('conhub');

/**
 * 참조자 목록 수정
 * @returns ConhubResponseContractWatcherUpdateDto OK
 * @throws ApiError
 */
export function updateContractWatchers(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ContractWatcherForm;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseContractWatcherUpdateDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/contracts/{contractId}/watchers/v1',
            path: {
                contractId: contractId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 계약서 정보 조회
 * @returns ConhubResponseContractInformationDto contract detail, counterparties, related workflows, files, watchers
 * @throws ApiError
 */
export function getContractInformation(
    {
        contractId,
    }: {
        contractId: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseContractInformationDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/contracts/{contractId}/v1',
            path: {
                contractId: contractId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 계약서 정보 수정
 * @returns ConhubResponseContractInformationUpdateDto OK
 * @throws ApiError
 */
export function updateContractInformation(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ContractInformationUpdateForm;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseContractInformationUpdateDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/contracts/{contractId}/v1',
            path: {
                contractId: contractId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 계약서 삭제
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function deleteContract(
    {
        contractId,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/contracts/{contractId}/v1',
            path: {
                contractId: contractId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 계약서명 변경
 * @returns ConhubResponseContractCreatedDto OK
 * @throws ApiError
 */
export function updateContractTitle(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ContractTitleUpdateForm;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseContractCreatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/contracts/{contractId}/title/v1',
            path: {
                contractId: contractId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 계약서 리마인더 조회
 * @returns ConhubResponseContractReminderDto OK
 * @throws ApiError
 */
export function getContractReminder(
    {
        contractId,
        reminderId,
    }: {
        contractId: number;
        reminderId: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseContractReminderDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/contracts/{contractId}/reminders/{reminderId}/v1',
            path: {
                contractId: contractId,
                reminderId: reminderId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 계약서 리마인더 업데이트
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function updateContractReminder(
    {
        contractId,
        reminderId,
        requestBody,
    }: {
        contractId: number;
        reminderId: number;
        requestBody: ReminderCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/contracts/{contractId}/reminders/{reminderId}/v1',
            path: {
                contractId: contractId,
                reminderId: reminderId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 계약서 리마인더 삭제
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function deleteContractReminder(
    {
        contractId,
        reminderId,
    }: {
        contractId: number;
        reminderId: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/contracts/{contractId}/reminders/{reminderId}/v1',
            path: {
                contractId: contractId,
                reminderId: reminderId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 관련 계약 목록 수정
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function updateRelatedContracts(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: RelatedContractsForm;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/contracts/{contractId}/related-contracts/v1',
            path: {
                contractId: contractId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 계약 상대방 조회
 * @returns ConhubResponseContractCounterpartyDto OK
 * @throws ApiError
 */
export function getContractCounterparty(
    {
        contractId,
        counterpartyId,
    }: {
        contractId: number;
        counterpartyId: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseContractCounterpartyDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/contracts/{contractId}/counterparties/{counterpartyId}/v1',
            path: {
                contractId: contractId,
                counterpartyId: counterpartyId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 계약 상대방 수정
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function updateContractCounterparty(
    {
        contractId,
        counterpartyId,
        requestBody,
    }: {
        contractId: number;
        counterpartyId: number;
        requestBody: ContractCounterpartyForm;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/contracts/{contractId}/counterparties/{counterpartyId}/v1',
            path: {
                contractId: contractId,
                counterpartyId: counterpartyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 계약 상대방 삭제
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function deleteContractCounterparty(
    {
        contractId,
        counterpartyId,
    }: {
        contractId: number;
        counterpartyId: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/contracts/{contractId}/counterparties/{counterpartyId}/v1',
            path: {
                contractId: contractId,
                counterpartyId: counterpartyId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 워크플로우 스텝 액션
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function doWorkflowStepAction(
    {
        contractId,
        workflowId,
        stepCode,
        actionId,
        requestBody,
    }: {
        contractId: number;
        workflowId: number;
        stepCode: string;
        actionId: number;
        requestBody: WorkflowStepActionForm;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/contracts/{contractId}/workflows/{workflowId}/steps/{stepCode}/actions/{actionId}/v1',
            path: {
                contractId: contractId,
                workflowId: workflowId,
                stepCode: stepCode,
                actionId: actionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 워크플로우 생성
 * @returns ConhubResponseWorkflowDto OK
 * @throws ApiError
 */
export function createWorkflow(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: WorkflowForm;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseWorkflowDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/contracts/{contractId}/workflows/v1',
            path: {
                contractId: contractId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 워크플로우 이벤트 코멘트 추가
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function addComment(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: WorkflowCommentForm;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/contracts/{contractId}/workflows/latest/comments/v1',
            path: {
                contractId: contractId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 계약서 리마인더 목록 조회
 * @returns ConhubResponseListContractReminderSimpleDto OK
 * @throws ApiError
 */
export function getContractReminderList(
    {
        contractId,
    }: {
        contractId: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseListContractReminderSimpleDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/contracts/{contractId}/reminders/v1',
            path: {
                contractId: contractId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 계약서 리마인더 생성
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function createContractReminder(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ReminderCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/contracts/{contractId}/reminders/v1',
            path: {
                contractId: contractId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 첨부파일 추가
 * @returns ConhubResponseContractFileDto OK
 * @throws ApiError
 */
export function addAttachment(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ContractFileForm;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseContractFileDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/contracts/{contractId}/files/v1',
            path: {
                contractId: contractId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 주계약서 조회
 * @returns ConhubResponseDocument OK
 * @throws ApiError
 */
export function getDocument(
    {
        contractId,
        revision,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
        /**
         * 계약서 버전. 0~
         */
        revision?: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseDocument> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/contracts/{contractId}/documents/v1',
            path: {
                contractId: contractId,
            },
            query: {
                revision: revision,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 주계약서 파일 업로드 by JSON
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function uploadDocument(
    {
        contractId,
        requestBody,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
        requestBody: Document;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/contracts/{contractId}/documents/v1',
            path: {
                contractId: contractId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 주계약서 파일 업로드 by File
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function uploadDocumentByFile(
    {
        contractId,
        formData,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
        formData?: {
            /**
             * 계약서(file)
             */
            file: Blob;
        };
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/contracts/{contractId}/documents/by-file/v1',
            path: {
                contractId: contractId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        }),
        ...requestConfig,
    });
}
/**
 * 계약 상대방 생성
 * @returns ConhubResponseContractCounterpartyDto OK
 * @throws ApiError
 */
export function createContractCounterparty(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: ContractCounterpartyForm;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseContractCounterpartyDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/contracts/{contractId}/counterparties/v1',
            path: {
                contractId: contractId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 계약서 북마크 등록
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function registerBookmark(
    {
        contractId,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/contracts/{contractId}/bookmarks/v1',
            path: {
                contractId: contractId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 계약서 북마크 삭제
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function deleteBookmark(
    {
        contractId,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/contracts/{contractId}/bookmarks/v1',
            path: {
                contractId: contractId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 계약서 생성 - 표준 계약서로 생성
 * @returns ConhubResponseContractCreatedDto OK
 * @throws ApiError
 */
export function createContractByStandardForm(
    {
        requestBody,
    }: {
        requestBody: ContractCreateStandardForm;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseContractCreatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/contracts/by-standard-form/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 계약서 생성 - 파일 업로드로 생성
 * @returns ConhubResponseContractCreatedDto OK
 * @throws ApiError
 */
export function createContractByFile(
    {
        requestBody,
    }: {
        requestBody: ContractCreateFileForm;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseContractCreatedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/contracts/by-file/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 계약서 유형 목록 조회
 * @returns ConhubResponseListContractTypeDto OK
 * @throws ApiError
 */
export function getContractTypes(
    {
        workspaceId,
    }: {
        workspaceId: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseListContractTypeDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/workspaces/{workspaceId}/contracts/types/v1',
            path: {
                workspaceId: workspaceId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 스텝 별 액션 정의 리스트
 * @returns ConhubResponseListWorkflowStepActionDto OK
 * @throws ApiError
 */
export function getActionDefs(
    {
        stepCode,
    }: {
        stepCode: string;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseListWorkflowStepActionDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/workflows/actions/defs/step/{stepCode}/v1',
            path: {
                stepCode: stepCode,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 내 계약서 목록 조회
 * @returns ConhubResponsePageMyRequestDto OK
 * @throws ApiError
 */
export function pageMyRequests(
    {
        createdDateStart,
        createdDateEnd,
        searchKeyword,
        contractTypes,
        userIds,
        sortField,
        sortDirection,
        page,
        size = 30,
        sort,
    }: {
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 정렬 기준
         */
        sortField?: 'WORKFLOW_CREATED_AT' | 'UPDATED_AT';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponsePageMyRequestDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/my-requests/v1',
            query: {
                createdDateStart: createdDateStart,
                createdDateEnd: createdDateEnd,
                searchKeyword: searchKeyword,
                contractTypes: contractTypes,
                userIds: userIds,
                sortField: sortField,
                sortDirection: sortDirection,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 내 그룹 계약서 목록 조회
 * @returns ConhubResponsePageMyRequestDto OK
 * @throws ApiError
 */
export function pageMyGroupRequests(
    {
        groupIds,
        createdDateStart,
        createdDateEnd,
        searchKeyword,
        contractTypes,
        userIds,
        sortField,
        sortDirection,
        page,
        size = 30,
        sort,
    }: {
        /**
         * 그룹
         */
        groupIds?: Array<number>;
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 정렬 기준
         */
        sortField?: 'WORKFLOW_CREATED_AT' | 'UPDATED_AT';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponsePageMyRequestDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/my-requests/groups/v1',
            query: {
                groupIds: groupIds,
                createdDateStart: createdDateStart,
                createdDateEnd: createdDateEnd,
                searchKeyword: searchKeyword,
                contractTypes: contractTypes,
                userIds: userIds,
                sortField: sortField,
                sortDirection: sortDirection,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 내 그룹 계약서 목록 조회
 * @returns any OK
 * @throws ApiError
 */
export function downloadMyGroupRequests(
    {
        groupIds,
        createdDateStart,
        createdDateEnd,
        searchKeyword,
        contractTypes,
        userIds,
        sortField,
        sortDirection,
    }: {
        /**
         * 그룹
         */
        groupIds?: Array<number>;
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 정렬 기준
         */
        sortField?: 'WORKFLOW_CREATED_AT' | 'UPDATED_AT';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
    },
    requestConfig: any = {},
): AxiosPromise<any> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/my-requests/groups/download/v1',
            query: {
                groupIds: groupIds,
                createdDateStart: createdDateStart,
                createdDateEnd: createdDateEnd,
                searchKeyword: searchKeyword,
                contractTypes: contractTypes,
                userIds: userIds,
                sortField: sortField,
                sortDirection: sortDirection,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 내 계약서 목록 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadMyRequests(
    {
        createdDateStart,
        createdDateEnd,
        searchKeyword,
        contractTypes,
        userIds,
        sortField,
        sortDirection,
    }: {
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 계약서 유형
         */
        contractTypes?: Array<string>;
        /**
         * 사용자
         */
        userIds?: Array<number>;
        /**
         * 정렬 기준
         */
        sortField?: 'WORKFLOW_CREATED_AT' | 'UPDATED_AT';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
    },
    requestConfig: any = {},
): AxiosPromise<any> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/my-requests/download/v1',
            query: {
                createdDateStart: createdDateStart,
                createdDateEnd: createdDateEnd,
                searchKeyword: searchKeyword,
                contractTypes: contractTypes,
                userIds: userIds,
                sortField: sortField,
                sortDirection: sortDirection,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 워크플로우 스텝 목록 조회
 * @returns ConhubResponseListWorkflowStepDto OK
 * @throws ApiError
 */
export function getWorkflowSteps(
    {
        contractId,
        workflowId,
    }: {
        contractId: number;
        workflowId: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseListWorkflowStepDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/contracts/{contractId}/workflows/{workflowId}/steps/v1',
            path: {
                contractId: contractId,
                workflowId: workflowId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 계약서 워크 플로우 이벤트 목록 조회
 * @returns ConhubResponsePageWorkflowEventDto OK
 * @throws ApiError
 */
export function getContractWorkflowEvents(
    {
        contractId,
        page,
        size = 20,
        sort,
    }: {
        contractId: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponsePageWorkflowEventDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/contracts/{contractId}/workflows/events/v1',
            path: {
                contractId: contractId,
            },
            query: {
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 계약서 워크 플로우 진행내역 조회
 * @returns ConhubResponsePageWorkflowEventSimpleDto OK
 * @throws ApiError
 */
export function getContractWorkflowEventsWithOnlySteps(
    {
        contractId,
        page,
        size = 20,
        sort,
    }: {
        contractId: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponsePageWorkflowEventSimpleDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/contracts/{contractId}/workflows/events/steps/v1',
            path: {
                contractId: contractId,
            },
            query: {
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 현재 워크플로우 스텝 목록 조회
 * @returns ConhubResponseListWorkflowStepDto OK
 * @throws ApiError
 */
export function getCurrentWorkflowSteps(
    {
        contractId,
    }: {
        contractId: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseListWorkflowStepDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/contracts/{contractId}/workflows/current/steps/v1',
            path: {
                contractId: contractId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * @returns ConhubResponseListContractWorkflowStepGroupDto OK
 * @throws ApiError
 */
export function getCurrentWorkflowStepGroups(
    {
        contractId,
    }: {
        contractId: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseListContractWorkflowStepGroupDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/contracts/{contractId}/workflows/current/step-groups/v1',
            path: {
                contractId: contractId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 현재 워크플로우 라운드 조회
 * @returns ConhubResponseInteger OK
 * @throws ApiError
 */
export function getCurrentWorkflowRound(
    {
        contractId,
    }: {
        contractId: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseInteger> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/contracts/{contractId}/workflows/current/round/v1',
            path: {
                contractId: contractId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 주계약서 파일 리비전 목록 조회
 * @returns ConhubResponseContractFileRevisionDto OK
 * @throws ApiError
 */
export function getFileRevisions(
    {
        contractId,
    }: {
        contractId: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseContractFileRevisionDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/contracts/{contractId}/documents/revisions/v1',
            path: {
                contractId: contractId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 주계약서 파일 다운로드
 * @returns binary OK
 * @throws ApiError
 */
export function downloadDocument(
    {
        contractId,
        fileFormat,
        revision,
    }: {
        /**
         * 계약서 ID
         */
        contractId: number;
        /**
         * 다운로드 포맷
         */
        fileFormat: 'DOCX';
        /**
         * 계약서 버전. 0~
         */
        revision?: number;
    },
    requestConfig: any = {},
): AxiosPromise<Blob> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/contracts/{contractId}/documents/download/v1',
            path: {
                contractId: contractId,
            },
            query: {
                revision: revision,
                fileFormat: fileFormat,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 나의 관련 계약 목록 조회
 * @returns ConhubResponsePageMyRelatedContractDto OK
 * @throws ApiError
 */
export function listMyRelatedContracts(
    {
        createdAtStart,
        createdAtEnd,
        contractTitle,
        page,
        size = 20,
        sort,
    }: {
        createdAtStart?: string;
        createdAtEnd?: string;
        contractTitle?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponsePageMyRelatedContractDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/contracts/my-related/v1',
            query: {
                createdAtStart: createdAtStart,
                createdAtEnd: createdAtEnd,
                contractTitle: contractTitle,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 워크스페이스별 계약서 메타 조회
 * @returns ConhubResponseMapStringListObject OK
 * @throws ApiError
 */
export function getContractMeta(requestConfig: any = {}): AxiosPromise<ConhubResponseMapStringListObject> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/contracts/meta/v1',
        }),
        ...requestConfig,
    });
}
/**
 * 계약서 북마크 조회
 * @returns ConhubResponsePageContractDto OK
 * @throws ApiError
 */
export function pageMyContractBookmarks(
    {
        page,
        size = 30,
        sort,
    }: {
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponsePageContractDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/contracts/bookmarks/v1',
            query: {
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 나의 배정해야 할 의뢰 조회
 * @returns ConhubResponseListAssignmentRequestDto OK
 * @throws ApiError
 */
export function getToBeAssignedContractWorkflowStep(
    {
        contractLanguage,
        workflowCreatedBy,
        contractType,
    }: {
        /**
         * 계약서 언어
         */
        contractLanguage?: 'KOREAN' | 'ENGLISH' | 'ETC';
        /**
         * 의뢰자 id
         */
        workflowCreatedBy?: number;
        /**
         * 계약서 코드
         */
        contractType?: 'SA' | 'NDA' | 'LOGISTICS' | 'RSA' | 'SALES' | 'DISTRIBUTION' | 'CONSULTING' | 'ADVERTISING' | 'SERVICE' | 'OEM' | 'CONSTRUCTION' | 'BCA' | 'MOU';
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseListAssignmentRequestDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/assignments/my-related/to-be-assigned/v1',
            query: {
                contractLanguage: contractLanguage,
                workflowCreatedBy: workflowCreatedBy,
                contractType: contractType,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 사람 별 현재 업무 현황 조회
 * @returns ConhubResponseListWorkflowStepCountDto OK
 * @throws ApiError
 */
export function getCurrentStepStatusCountByMember(
    {
        dateStart,
    }: {
        dateStart?: string;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseListWorkflowStepCountDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/assignments/current-status/count-by-member/v1',
            query: {
                dateStart: dateStart,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 멤버별 할당받은 워크플로우 현황 조회
 * @returns ConhubResponseListContractWorkflowStepDto OK
 * @throws ApiError
 */
export function getContractWorkflowStep(
    {
        stepIds,
    }: {
        stepIds: Array<number>;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponseListContractWorkflowStepDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/assignments/current-status/by-step-id/v1',
            query: {
                stepIds: stepIds,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 워크플로우 취소
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function cancelWorkflow(
    {
        contractId,
        workflowId,
        requestBody,
    }: {
        contractId: number;
        workflowId: number;
        requestBody: WorkflowCancelForm;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/contracts/{contractId}/workflows/{workflowId}/v1',
            path: {
                contractId: contractId,
                workflowId: workflowId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 첨부파일 삭제
 * @returns ConhubResponse OK
 * @throws ApiError
 */
export function deleteAttachment(
    {
        contractId,
        fileId,
    }: {
        contractId: number;
        fileId: number;
    },
    requestConfig: any = {},
): AxiosPromise<ConhubResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/contracts/{contractId}/files/{fileId}/v1',
            path: {
                contractId: contractId,
                fileId: fileId,
            },
        }),
        ...requestConfig,
    });
}
