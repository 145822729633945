/* eslint-disable */
// https://dev.bhsn.it/api/daredevil/swagger-ui/index.html
import { toAxiosOptions, createServiceAxiosInstance } from '$lib/utils';
import type { AxiosPromise } from 'axios';

export const axiosInstance = createServiceAxiosInstance('daredevil');

/**
 * Activity Feed 리스트 조회
 * @returns DareDevilResponseListActivityFeedDto OK
 * @throws ApiError
 */
export function getActivityFeeds(
    {
        litigationId,
    }: {
        /**
         * 송무 ID
         */
        litigationId: number;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponseListActivityFeedDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/litigations/{litigationId}/activity-feeds/v1',
            path: {
                litigationId: litigationId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * Activity Feed 생성
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function createActivityFeed(
    {
        litigationId,
        requestBody,
    }: {
        /**
         * 송무 ID
         */
        litigationId: number;
        requestBody: ActivityFeedCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/litigations/{litigationId}/activity-feeds/v1',
            path: {
                litigationId: litigationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * Activity Feed 파일 다운로드
 * @returns binary OK
 * @throws ApiError
 */
export function downloadActivityFeedFile(
    {
        litigationId,
        activityFeedId,
        fileKey,
    }: {
        /**
         * 사건 ID
         */
        litigationId: number;
        /**
         * 의견 및 히스토리 ID
         */
        activityFeedId: number;
        /**
         * 사건 파일 키
         */
        fileKey: string;
    },
    requestConfig: any = {},
): AxiosPromise<Blob> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/litigations/{litigationId}/activity-feeds/{activityFeedId}/file/v1',
            path: {
                litigationId: litigationId,
                activityFeedId: activityFeedId,
            },
            query: {
                fileKey: fileKey,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 대법원 사이트 연동
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function syncLawsuit(
    {
        litigationId,
        requestBody,
    }: {
        /**
         * litigation 의 id
         */
        litigationId: number;
        requestBody: LawsuitSyncForm;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/litigations/{litigationId}/lawsuit/sync/v1',
            path: {
                litigationId: litigationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 사건 일반 내용 조회
 * @returns DareDevilResponseLawsuitDto OK
 * @throws ApiError
 */
export function getLawsuit(
    {
        litigationId,
    }: {
        /**
         * 송무 ID
         */
        litigationId: number;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponseLawsuitDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/litigations/{litigationId}/lawsuit/general',
            path: {
                litigationId: litigationId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 사건 진행 내용 목록 조회
 * @returns DareDevilResponseListLawsuitEventDto OK
 * @throws ApiError
 */
export function getLawsuitEvents(
    {
        litigationId,
    }: {
        /**
         * litigation 의 id
         */
        litigationId: number;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponseListLawsuitEventDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/litigations/{litigationId}/lawsuit/events/v1',
            path: {
                litigationId: litigationId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 송무 비용 생성/수정 화면에서 사용하는 코드들을 조회한다.
 * @returns DareDevilResponseMapStringListObject OK
 * @throws ApiError
 */
export function getLitigationExpenseCodes(requestConfig: any = {}): AxiosPromise<DareDevilResponseMapStringListObject> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/litigations/expenses/codes/v1',
        }),
        ...requestConfig,
    });
}
/**
 * 대법원 연동 화면에서 사용하는 코드들을 조회한다.
 * @returns DareDevilResponseMapStringListObject OK
 * @throws ApiError
 */
export function getLawsuitCodes(requestConfig: any = {}): AxiosPromise<DareDevilResponseMapStringListObject> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/lawsuits/codes/v1',
        }),
        ...requestConfig,
    });
}
/**
 * Health Check
 * @returns string OK
 * @throws ApiError
 */
export function hello(requestConfig: any = {}): AxiosPromise<string> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/hello',
        }),
        ...requestConfig,
    });
}
/**
 * 송무 비용 수정
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function updateExpense(
    {
        id,
        litigationId,
        requestBody,
    }: {
        /**
         * expense 의 id
         */
        id: number;
        /**
         * litigation 의 id
         */
        litigationId: number;
        requestBody: ExpenseCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/litigations/{litigationId}/expenses/{id}/v1',
            path: {
                id: id,
                litigationId: litigationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 송무 상세 조회
 * @returns DareDevilResponseLitigationDto OK
 * @throws ApiError
 */
export function getLitigation(
    {
        id,
    }: {
        /**
         * litigation 의 id
         */
        id: number;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponseLitigationDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/litigations/{id}/v1',
            path: {
                id: id,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 송무 수정
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function updateLitigation(
    {
        id,
        requestBody,
    }: {
        /**
         * litigation 의 id
         */
        id: number;
        requestBody: LitigationUpdateForm;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/litigations/{id}/v1',
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 송무 삭제
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function deleteLitigation(
    {
        id,
    }: {
        /**
         * litigation 의 id
         */
        id: number;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/litigations/{id}/v1',
            path: {
                id: id,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 송무 완료 처리
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function completeLitigation(
    {
        id,
        requestBody,
    }: {
        /**
         * litigation 의 id
         */
        id: number;
        requestBody: LitigationCompletionForm;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/litigations/{id}/completion/v1',
            path: {
                id: id,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 송무 비용 목록 조회
 * @returns DareDevilResponsePageLitigationExpenseDto OK
 * @throws ApiError
 */
export function getExpenses(
    {
        litigationId,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 송무 ID
         */
        litigationId: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponsePageLitigationExpenseDto> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/litigations/{litigationId}/expenses/v1',
            path: {
                litigationId: litigationId,
            },
            query: {
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 송무 비용 생성
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function createExpense(
    {
        litigationId,
        requestBody,
    }: {
        /**
         * litigation 의 id
         */
        litigationId: number;
        requestBody: ExpenseCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/litigations/{litigationId}/expenses/v1',
            path: {
                litigationId: litigationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 송무 비용 삭제
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function deleteExpenses(
    {
        litigationId,
        requestBody,
    }: {
        /**
         * litigation 의 id
         */
        litigationId: number;
        requestBody: Array<number>;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/litigations/{litigationId}/expenses/v1',
            path: {
                litigationId: litigationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 송무 생성
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function createLitigation(
    {
        requestBody,
    }: {
        requestBody: LitigationCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/litigations/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 송무 첨부파일 다운로드
 * @returns binary OK
 * @throws ApiError
 */
export function downloadFile(
    {
        litigationId,
        fileKey,
    }: {
        /**
         * 사건 ID
         */
        litigationId: number;
        /**
         * 사건 파일 키
         */
        fileKey: string;
    },
    requestConfig: any = {},
): AxiosPromise<Blob> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/litigations/{litigationId}/file/v1',
            path: {
                litigationId: litigationId,
            },
            query: {
                fileKey: fileKey,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 송무 비용 - 엑셀 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function getExpenseExcel(
    {
        litigationId,
    }: {
        /**
         * litigation 의 id
         */
        litigationId: number;
    },
    requestConfig: any = {},
): AxiosPromise<any> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/litigations/{litigationId}/expenses/excel/v1',
            path: {
                litigationId: litigationId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 송무 검색 - 간단 송무 검색
 * @returns DareDevilResponsePageSimpleLitigationVo OK
 * @throws ApiError
 */
export function searchSimpleLitigation(
    {
        createdDateStart,
        createdDateEnd,
        litigationName,
        status,
        ownerIds,
        groupIds,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 사건명
         */
        litigationName?: string;
        /**
         * 종결 타입(related/v1:litigationStatus)
         */
        status?: 'IN_PROGRESS' | 'COMPLETED';
        /**
         * 담당자 목록
         */
        ownerIds?: Array<number>;
        /**
         * 그룹 목록 (현재 미구현)
         */
        groupIds?: Array<number>;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponsePageSimpleLitigationVo> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/litigations/simple-search/v1',
            query: {
                createdDateStart: createdDateStart,
                createdDateEnd: createdDateEnd,
                litigationName: litigationName,
                status: status,
                ownerIds: ownerIds,
                groupIds: groupIds,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 송무 검색
 * @returns DareDevilResponsePageLitigationVo OK
 * @throws ApiError
 */
export function searchLitigation(
    {
        createdDateStart,
        createdDateEnd,
        keyword,
        categories,
        ownerIds,
        groupIds,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 검색어
         */
        keyword?: string;
        /**
         * 사건 분류(codes/v1:litigationCategory)
         */
        categories?: Array<'CIVIL' | 'CRIMINAL' | 'ADMINISTRATIVE' | 'PATENT' | 'APPLICATION' | 'EXECUTION'>;
        /**
         * 담당자 목록
         */
        ownerIds?: Array<number>;
        /**
         * 그룹 목록 (현재 미구현)
         */
        groupIds?: Array<number>;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponsePageLitigationVo> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/litigations/search/v1',
            query: {
                createdDateStart: createdDateStart,
                createdDateEnd: createdDateEnd,
                keyword: keyword,
                categories: categories,
                ownerIds: ownerIds,
                groupIds: groupIds,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 송무 검색 - 엑셀 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function searchLitigationExcel(
    {
        createdDateStart,
        createdDateEnd,
        keyword,
        categories,
        ownerIds,
        groupIds,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 검색어
         */
        keyword?: string;
        /**
         * 사건 분류(codes/v1:litigationCategory)
         */
        categories?: Array<'CIVIL' | 'CRIMINAL' | 'ADMINISTRATIVE' | 'PATENT' | 'APPLICATION' | 'EXECUTION'>;
        /**
         * 담당자 목록
         */
        ownerIds?: Array<number>;
        /**
         * 그룹 목록 (현재 미구현)
         */
        groupIds?: Array<number>;
    },
    requestConfig: any = {},
): AxiosPromise<any> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/litigations/excel/v1',
            query: {
                createdDateStart: createdDateStart,
                createdDateEnd: createdDateEnd,
                keyword: keyword,
                categories: categories,
                ownerIds: ownerIds,
                groupIds: groupIds,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 송무 생성/수정 화면에서 사용하는 코드들을 조회한다.
 * @returns DareDevilResponseMapStringListObject OK
 * @throws ApiError
 */
export function getLitigationCodes(requestConfig: any = {}): AxiosPromise<DareDevilResponseMapStringListObject> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/litigations/codes/v1',
        }),
        ...requestConfig,
    });
}
/**
 * 자문 간단 검색
 * @returns DareDevilResponsePageSimpleConsultingVo OK
 * @throws ApiError
 */
export function searchSimpleConsulting(
    {
        createdDateStart,
        createdDateEnd,
        step,
        keyword,
        ownerIds,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 자문 진행 상태 타입(related/v1:stepCode)
         */
        step?: 'REVIEW_REQUEST' | 'REVIEWER_ASSIGNMENT' | 'REVIEW' | 'REVIEW_AGREEMENT' | 'APPROVAL';
        /**
         * 검색어
         */
        keyword?: string;
        /**
         * 자문 의뢰자 목록
         */
        ownerIds?: Array<number>;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponsePageSimpleConsultingVo> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/consultings/simple-search/v1',
            query: {
                createdDateStart: createdDateStart,
                createdDateEnd: createdDateEnd,
                step: step,
                keyword: keyword,
                ownerIds: ownerIds,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 자문 검색
 * @returns DareDevilResponsePageConsultingVo OK
 * @throws ApiError
 */
export function searchConsulting(
    {
        createdDateStart,
        createdDateEnd,
        ownerId,
        keyword,
        categories,
        reviewerIds,
        groupIds,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 자문 의뢰자
         */
        ownerId?: number;
        /**
         * 검색어
         */
        keyword?: string;
        /**
         * 자문 종류(codes/v1:consultingType)
         */
        categories?: Array<'CONTRACT' | 'EMPLOYMENT' | 'LABOR' | 'COMPLIANCE' | 'ESG' | 'TAX' | 'ADMINISTRATIVE' | 'IPR' | 'FAIR_TRADE' | 'SUBCONTRACTING' | 'INTERNATIONAL' | 'PRIVACY' | 'OTHER'>;
        /**
         * 자문 검토자 목록
         */
        reviewerIds?: Array<number>;
        /**
         * 그룹 목록 (현재 미구현)
         */
        groupIds?: Array<number>;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponsePageConsultingVo> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/consultings/search/v1',
            query: {
                createdDateStart: createdDateStart,
                createdDateEnd: createdDateEnd,
                ownerId: ownerId,
                keyword: keyword,
                categories: categories,
                reviewerIds: reviewerIds,
                groupIds: groupIds,
                page: page,
                size: size,
                sort: sort,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 자문 검색 - 엑셀 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function searchConsultingExcel(
    {
        createdDateStart,
        createdDateEnd,
        ownerId,
        keyword,
        categories,
        reviewerIds,
        groupIds,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 자문 의뢰자
         */
        ownerId?: number;
        /**
         * 검색어
         */
        keyword?: string;
        /**
         * 자문 종류(codes/v1:consultingType)
         */
        categories?: Array<'CONTRACT' | 'EMPLOYMENT' | 'LABOR' | 'COMPLIANCE' | 'ESG' | 'TAX' | 'ADMINISTRATIVE' | 'IPR' | 'FAIR_TRADE' | 'SUBCONTRACTING' | 'INTERNATIONAL' | 'PRIVACY' | 'OTHER'>;
        /**
         * 자문 검토자 목록
         */
        reviewerIds?: Array<number>;
        /**
         * 그룹 목록 (현재 미구현)
         */
        groupIds?: Array<number>;
    },
    requestConfig: any = {},
): AxiosPromise<any> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/consultings/excel/v1',
            query: {
                createdDateStart: createdDateStart,
                createdDateEnd: createdDateEnd,
                ownerId: ownerId,
                keyword: keyword,
                categories: categories,
                reviewerIds: reviewerIds,
                groupIds: groupIds,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 자문 화면에서 사용하는 코드들을 조회한다.
 * @returns DareDevilResponseMapStringListObject OK
 * @throws ApiError
 */
export function getConsultingCodes(requestConfig: any = {}): AxiosPromise<DareDevilResponseMapStringListObject> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/consultings/codes/v1',
        }),
        ...requestConfig,
    });
}
/**
 * 자문 삭제
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function deleteConsulting(
    {
        consultingId,
    }: {
        /**
         * 자문의 id
         */
        consultingId: number;
    },
    requestConfig: any = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/consultings/{consultingId}/v1',
            path: {
                consultingId: consultingId,
            },
        }),
        ...requestConfig,
    });
}
