/* eslint-disable */
// https://dev.bhsn.it/api/heimdall/swagger-ui/index.html
import { toAxiosOptions, createServiceAxiosInstance } from '$lib/utils';
import type { AxiosPromise } from 'axios';

export const axiosInstance = createServiceAxiosInstance('heimdall');

/**
 * 사용자 자격증명 수정
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function resetUserCredential(
    {
        requestBody,
    }: {
        requestBody: UserCredentialResetForm;
    },
    requestConfig: any = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'PUT',
            url: '/user-credentials/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 자격증명 생성
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function createUserCredential(
    {
        requestBody,
    }: {
        requestBody: UserCredentialCreateForm;
    },
    requestConfig: any = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/user-credentials/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 사용자 자격증명 제거
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function deleteUserCredential(
    {
        requestBody,
    }: {
        requestBody: UserCredentialDeleteForm;
    },
    requestConfig: any = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'DELETE',
            url: '/user-credentials/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 서비스 토큰 조회
 * @returns HeimdallResponseServiceToken OK
 * @throws ApiError
 */
export function issueToken(
    {
        requestBody,
    }: {
        requestBody: ServiceAuthForm;
    },
    requestConfig: any = {},
): AxiosPromise<HeimdallResponseServiceToken> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/service-auth/tokens/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 재발급
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function refresh(
    {
        lissRft,
    }: {
        lissRft: string;
    },
    requestConfig: any = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/refresh/v1',
            cookies: {
                liss_rft: lissRft,
            },
        }),
        ...requestConfig,
    });
}
/**
 * 비밀번호 초기화
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function resetCredential(
    {
        requestBody,
    }: {
        requestBody: RedirectMailForm;
    },
    requestConfig: any = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/message/reset-credentials/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 로그아웃
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function logout(requestConfig: any = {}): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/logout/v1',
        }),
        ...requestConfig,
    });
}
/**
 * 로그인
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function login(
    {
        requestBody,
    }: {
        requestBody: LoginForm;
    },
    requestConfig: any = {},
): AxiosPromise<HeimdallResponse> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/login/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 로그인 (개발용 non-cookie 버전)
 * @returns HeimdallResponseTokenPair OK
 * @throws ApiError
 */
export function loginForDev(
    {
        requestBody,
    }: {
        requestBody: LoginForm;
    },
    requestConfig: any = {},
): AxiosPromise<HeimdallResponseTokenPair> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'POST',
            url: '/login/dev/v1',
            body: requestBody,
            mediaType: 'application/json',
        }),
        ...requestConfig,
    });
}
/**
 * 테넌트/워크스페이스 별 사용자 권한 목록 조회
 * @returns HeimdallResponseListString OK
 * @throws ApiError
 */
export function listWorkspaceUserPermissions(
    {
        tenantId,
        workspaceId,
    }: {
        tenantId: number;
        workspaceId: number;
    },
    requestConfig: any = {},
): AxiosPromise<HeimdallResponseListString> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/permissions/v2',
            query: {
                tenantId: tenantId,
                workspaceId: workspaceId,
            },
        }),
        ...requestConfig,
    });
}
/**
 * @deprecated
 * 사용자 권한 목록 조회
 * @returns HeimdallResponseListString OK
 * @throws ApiError
 */
export function listPermissions(requestConfig: any = {}): AxiosPromise<HeimdallResponseListString> {
    return axiosInstance({
        ...toAxiosOptions({
            method: 'GET',
            url: '/permissions/v1',
        }),
        ...requestConfig,
    });
}
