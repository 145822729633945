import ToastSimpleContent from '$components/toasts/ToastSimpleContent.svelte';
import { toast } from '@zerodevx/svelte-toast';
import type { SvelteToastOptions } from '@zerodevx/svelte-toast/stores';

export function toastDefault(message: string, options?: SvelteToastOptions) {
    toast.push(message, {
        component: {
            src: ToastSimpleContent,
            props: {
                message,
                color: 'gray',
            },
            sendIdTo: 'toastId',
        },
        pausable: true,
        dismissable: false,
        ...options,
    });
}

export function toastSuccess(message: string, options?: SvelteToastOptions) {
    toast.push(message, {
        component: {
            src: ToastSimpleContent,
            props: {
                message,
                color: 'green',
            },
            sendIdTo: 'toastId',
        },
        pausable: true,
        dismissable: false,
        ...options,
    });
}

export function toastDanger(message: string, options?: SvelteToastOptions) {
    toast.push(message, {
        component: {
            src: ToastSimpleContent,
            props: {
                message,
                color: 'red',
            },
            sendIdTo: 'toastId',
        },
        pausable: true,
        dismissable: false,
        ...options,
    });
}
